<template>
  <div class="com-layout-CommonFooter text-center">
    ©{{ new Date().getFullYear() }} 上海餐易通科技有限公司 ©版权所有
    <a
      href="https://beian.miit.gov.cn/"
      target="_blank"
      style="margin-left: 10px"
      ><br v-if="getBrowser == 'phone'">沪ICP备18046026号-2</a
    >
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.com-layout-CommonFooter {
  padding: 25px;
  &,
  a {
    color: #748296;
  }
}
</style>